import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children }) => {
  const [isAuthChecking, setIsAuthChecking] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verificarToken = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token não encontrado');
        }

        const decodedToken = jwtDecode(token);
        if (!decodedToken || !decodedToken.userId) {
          throw new Error('Token inválido');
        }

        setIsAuthenticated(true);
      } catch (error) {
        console.error('Erro de autenticação:', error);
        message.error('Por favor, faça login novamente');
        navigate('/login');
      } finally {
        setIsAuthChecking(false);
      }
    };

    verificarToken();
  }, [navigate]);

  if (isAuthChecking) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" tip="Verificando autenticação..." />
      </div>
    );
  }

  return isAuthenticated ? children : null;
};

export default PrivateRoute; 