import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  Popconfirm,
  Select,
  Typography,
  InputNumber,
  Row,
  Col,
  Card,
  Empty,
} from 'antd';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const { Title } = Typography;
const { Option } = Select;

const MeusAnuncios = () => {
  const [anuncios, setAnuncios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    titulo: '',
    descricao: '',
    marca: '',
    modelo: '',
    ano: null,
    preco: null,
    quilometragem: null,
    combustivel: '',
    cor: '',
    fotos: [], // Array de URLs das fotos
    status: 'ativo',
  });
  const [form] = Form.useForm();
  const [usuarioId, setUsuarioId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUsuarioId(decodedToken.userId);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (usuarioId) {
      buscarAnuncios();
    }
  }, [usuarioId]);

  const buscarAnuncios = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/anuncios`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setAnuncios(response.data);
    } catch (error) {
      message.error('Erro ao buscar anúncios.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormData({
      id: null,
      titulo: '',
      descricao: '',
      marca: '',
      modelo: '',
      ano: null,
      preco: null,
      quilometragem: null,
      combustivel: '',
      cor: '',
      fotos: [],
      status: 'ativo',
    });
  };

  const onFinish = async (values) => {
    try {
      if (formData.id) {
        // Editar anúncio
        await axios.put(process.env.REACT_APP_API_URL + `/api/anuncios/${formData.id}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Anúncio atualizado com sucesso!');
      } else {
        // Criar anúncio (lógica já implementada em PublicarAnuncio.js)
        // ...
      }
      handleCancel();
      buscarAnuncios();
    } catch (error) {
      message.error('Erro ao salvar anúncio.');
      console.error(error);
    }
  };

  const editarAnuncio = (anuncio) => {
    if (anuncio.vendedorid !== usuarioId) {
      message.error('Você não tem permissão para editar este anúncio');
      return;
    }
    setFormData(anuncio);
    showModal();
  };

  const excluirAnuncio = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_URL + `/api/anuncios/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Anúncio excluído com sucesso!');
      buscarAnuncios();
    } catch (error) {
      message.error('Erro ao excluir anúncio.');
      console.error(error);
    }
  };

  const columns = [
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      sorter: (a, b) => a.titulo.localeCompare(b.titulo),
    },
    {
      title: 'Marca',
      dataIndex: 'marca',
      key: 'marca',
      sorter: (a, b) => a.marca.localeCompare(b.marca),
    },
    {
      title: 'Modelo',
      dataIndex: 'modelo',
      key: 'modelo',
      sorter: (a, b) => a.modelo.localeCompare(b.modelo),
    },
    {
      title: 'Ano',
      dataIndex: 'ano',
      key: 'ano',
      sorter: (a, b) => a.ano - b.ano,
    },
    {
        title: 'Preço',
        dataIndex: 'preco',
        key: 'preco',
        sorter: (a, b) => a.preco - b.preco,
        render: (preco) => `R$ ${parseFloat(preco).toFixed(2)}`, // Converte preco para número antes de formatar
      },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color = 'default';
        if (status === 'ativo') {
          color = 'green';
        } else if (status === 'inativo') {
          color = 'red';
        } else if (status === 'vendido') {
          color = 'blue';
        }
        return <span style={{ color }}>{status}</span>;
      },
    },
    {
      title: 'Ações',
      key: 'acoes',
      render: (text, anuncio) => (
        <Space size="middle">
          <Button type="link" onClick={() => editarAnuncio(anuncio)}>
            Editar
          </Button>
          <Popconfirm
            title="Tem certeza que deseja excluir este anúncio?"
            onConfirm={() => excluirAnuncio(anuncio.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="link" danger>
              Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <Title level={2}>Meus Anúncios</Title>
          {anuncios.length === 0 && ( // Exibe a mensagem se não houver anúncios
            <Empty description="Você ainda não possui nenhum anúncio publicado." />
          )}
          <Table
            dataSource={anuncios}
            columns={columns}
            loading={loading}
            rowKey="id"
            style={{ marginTop: '1rem' }}
            pagination={{ pageSize: 10 }}
            scroll={{ x: 'max-content' }}
          />
        </Card>
      </Col>
      <Modal
        title={formData.id ? 'Editar Anúncio' : 'Novo Anúncio'}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="anuncioForm"
          initialValues={formData}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="Título"
            name="titulo"
            rules={[{ required: true, message: 'Por favor, insira o título do anúncio!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Marca"
            name="marca"
            rules={[{ required: true, message: 'Por favor, insira a marca do carro!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Modelo"
            name="modelo"
            rules={[{ required: true, message: 'Por favor, insira o modelo do carro!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Ano"
            name="ano"
            rules={[{ required: true, message: 'Por favor, insira o ano do carro!' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Preço"
            name="preco"
            rules={[{ required: true, message: 'Por favor, insira o preço do carro!' }]}
          >
            <InputNumber
              formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label="Quilometragem"
            name="quilometragem"
            rules={[{ required: true, message: 'Por favor, insira a quilometragem do carro!' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Combustível"
            name="combustivel"
            rules={[{ required: true, message: 'Por favor, insira o tipo de combustível!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Cor"
            name="cor"
            rules={[{ required: true, message: 'Por favor, insira a cor do carro!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Fotos" name="fotos">
            <Input /> {/* Implemente um componente para upload de fotos */}
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: 'Por favor, selecione o status!' }]}
          >
            <Select>
              <Option value="ativo">Ativo</Option>
              <Option value="inativo">Inativo</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default MeusAnuncios;