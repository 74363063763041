import React from 'react';
import { Carousel } from 'antd';

import './ImageCarousel.css'; // Vamos criar este arquivo para estilos personalizados

// Importe as imagens do carrossel
import carrousel1 from './img/carrousel1.jpg';


import carrousel4 from './img/carrousel4.jpg';

function ImageCarousel() {
  return (
    <Carousel autoplay className="image-carousel">
      <div>
        <img src={carrousel1} alt="Carrossel 1" />
      </div>

      <div>
        <img src={carrousel4} alt="Carrossel 4" />
      </div>
    </Carousel>
  );
}

export default ImageCarousel;
