import React, { useState } from 'react';
import { 
  CalendarOutlined, 
  CarOutlined, 
  DollarOutlined, 
  TagOutlined,
  UserOutlined, 
  ShopOutlined, 
  WhatsAppOutlined,
  EnvironmentOutlined,
  FieldTimeOutlined,
  LeftOutlined,
  RightOutlined,
  CloseOutlined
} from '@ant-design/icons';
import './ImageCarouselModal.css';
import { Modal, Row, Col, Carousel, Divider, Descriptions, Typography, Avatar, Button, Tag, message } from 'antd';

const { Title, Text, Paragraph } = Typography;

const ImageCarouselModal = ({ onClose, vehicle }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);
  const carouselRef = React.useRef();
  const images = vehicle.images && vehicle.images.length > 0 ? vehicle.images : [vehicle.image];

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setCurrentImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }
  };

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
    if (carouselRef.current) {
      carouselRef.current.goTo(index);
    }
  };

  const handleImageClick = (image) => {
    setPreviewImage(image);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  const formatarPreco = (preco) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(preco);
  };

  const handleWhatsAppClick = (e) => {
    e?.stopPropagation();
    
    if (!vehicle.vendedor_contato) {
      message.error('Número de contato do vendedor não disponível');
      return;
    }

    const phoneNumber = vehicle.vendedor_contato.replace(/\D/g, '');
    const formattedPhone = phoneNumber.startsWith('55') ? phoneNumber : `55${phoneNumber}`;
    const messageText = `Olá! Vi seu anúncio do ${vehicle.name} (Código: ${vehicle.codigo}) no site e gostaria de mais informações.`;
    const whatsappUrl = `https://wa.me/${formattedPhone}?text=${encodeURIComponent(messageText)}`;
    
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content vehicle-detail-modal" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        
        <Row gutter={[24, 24]}>
          <Col xs={24} md={14}>
            <div className="carousel-container">
              <button className="carousel-arrow prev" onClick={handlePrevClick}>
                <LeftOutlined />
              </button>
              
              <Carousel
                ref={carouselRef}
                dots={false}
                afterChange={setCurrentImageIndex}
              >
                {images.map((image, index) => (
                  <div key={index} className="carousel-item">
                    <img 
                      src={image} 
                      alt={`${vehicle.name} - Imagem ${index + 1}`}
                      onClick={() => handleImageClick(image)}
                    />
                  </div>
                ))}
              </Carousel>

              <button className="carousel-arrow next" onClick={handleNextClick}>
                <RightOutlined />
              </button>
            </div>

            <div className="thumbnails-container">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`thumbnail-item ${index === currentImageIndex ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img 
                    src={image} 
                    alt={`Miniatura ${index + 1}`}
                  />
                </div>
              ))}
            </div>

            {/* Detalhes do Veículo */}
            <div className="details-section">
              <Descriptions bordered column={1}>
                <Descriptions.Item label="Ano">
                  <CalendarOutlined /> {vehicle.year}
                </Descriptions.Item>
                <Descriptions.Item label="Marca">
                  <TagOutlined /> {vehicle.manufacturer}
                </Descriptions.Item>
                <Descriptions.Item label="Modelo">
                  <CarOutlined /> {vehicle.model}
                </Descriptions.Item>
                <Descriptions.Item label="Publicado">
                  <FieldTimeOutlined /> {vehicle.createdat ? new Date(vehicle.createdat).toLocaleDateString() : 'Data não disponível'}
                </Descriptions.Item>
              </Descriptions>
            </div>

            <div className="description-section">
              <Title level={4}>Descrição</Title>
              <Paragraph>{vehicle.description}</Paragraph>
            </div>
          </Col>

          {/* Coluna da Direita - Informações */}
          <Col xs={24} md={10} className="vehicle-info">
            <div className="header-section">
              <Tag color={vehicle.tipoanuncio === 'Peca' ? 'blue' : 'green'}>
                {vehicle.tipoanuncio === 'Peca' ? 'Peça' : 'Veículo'}
              </Tag>
              <Title level={2}>{vehicle.name}</Title>
              <Text type="secondary">Código: {vehicle.codigo}</Text>
              <Title level={3} className="price">
                {formatarPreco(vehicle.price)}
              </Title>
            </div>

            <Divider />

            <div className="contact-section">
              <div className="vendor-info">
                <Avatar size={64} src={vehicle.vendedor_foto} icon={<UserOutlined />} />
                <div className="vendor-details">
                  <Text strong>Vendedor</Text>
                  <Text>{vehicle.vendedor}</Text>
                </div>
              </div>

              {vehicle.concessionaria && (
                <div className="dealer-info">
                  <Avatar size={64} src={vehicle.concessionaria_foto} icon={<ShopOutlined />} />
                  <div className="dealer-details">
                    <Text strong>Concessionária</Text>
                    <Text>{vehicle.concessionaria}</Text>
                  </div>
                </div>
              )}

              <Button 
                type="primary" 
                icon={<WhatsAppOutlined />} 
                block 
                onClick={handleWhatsAppClick}
                className="whatsapp-button"
              >
                Contatar Vendedor
              </Button>
            </div>
          </Col>
        </Row>

        {previewImage && (
          <div className="image-preview-modal" onClick={handleClosePreview}>
            <div className="image-preview-content">
              <button className="close-preview" onClick={handleClosePreview}>
                <CloseOutlined />
              </button>
              <img src={previewImage} alt="Visualização ampliada" onClick={(e) => e.stopPropagation()} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCarouselModal;